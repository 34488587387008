import { HttpClient } from '../../httpClient'
import { throwIfNotAllowedContentType, validateHttpResponse } from '../../httpClient/utils.ts'

export interface ICreateOrgInviteRequest {
	email: string
	managerId: string
	positionId: string
	roles: string[]
	path: string
}

export const createOrganizationInvite = async (client: HttpClient, invite: ICreateOrgInviteRequest) => {
	const response = await client.post(`/api/v2/invites/create`, invite)

	await validateHttpResponse(response)
	throwIfNotAllowedContentType(response, ['application/json'])

	return response.data
}
