import { useCallback, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useOrgAuth } from '../../OrgAuthProvider.ts'
import { IOrganization } from '../../services/nextpeakClient'

const DropdownOrganization = () => {
	const [dropdownOpen, setDropdownOpen] = useState(false)
	const { userOrganizations, handleOrganizationChange, authOrgUser, logOutOrganization } = useOrgAuth()

	const trigger = useRef<any>(null)
	const dropdown = useRef<any>(null)

	// close on click outside
	useEffect(() => {
		const clickHandler = ({ target }: MouseEvent) => {
			if (!dropdown.current) return
			if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return
			setDropdownOpen(false)
		}
		document.addEventListener('click', clickHandler)
		return () => document.removeEventListener('click', clickHandler)
	})

	// close if the esc key is pressed
	useEffect(() => {
		const keyHandler = ({ keyCode }: KeyboardEvent) => {
			if (!dropdownOpen || keyCode !== 27) return
			setDropdownOpen(false)
		}
		document.addEventListener('keydown', keyHandler)
		return () => document.removeEventListener('keydown', keyHandler)
	})

	const handeleItemClick = useCallback(
		(org: IOrganization) => {
			if (handleOrganizationChange && org.id !== authOrgUser?.orgId) {
				handleOrganizationChange(org, userOrganizations)
			}
			setDropdownOpen(!dropdownOpen)
		},
		[handleOrganizationChange, dropdownOpen, setDropdownOpen]
	)

	if (!authOrgUser || !handleOrganizationChange) {
		return null
	}

	const activeOrg = userOrganizations.find((org) => org.id === authOrgUser.orgId)

	return (
		<div className="relative">
			<Link ref={trigger} onClick={() => setDropdownOpen(!dropdownOpen)} className="flex items-center gap-4" to="#">
				<span className="hidden text-right lg:block">
					<span className="block text-sm font-medium text-black dark:text-white">{activeOrg?.name ?? 'Select organization'}</span>
				</span>
			</Link>

			{/* <!-- Dropdown Start --> */}
			<div
				ref={dropdown}
				onFocus={() => setDropdownOpen(true)}
				onBlur={() => setDropdownOpen(false)}
				className={`absolute right-0 mt-4 flex w-62.5 flex-col rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark ${
					dropdownOpen === true ? 'block' : 'hidden'
				}`}
			>
				{userOrganizations.map((org) => (
					<ul key={org.id} className="flex flex-col gap-5 border-b border-stroke px-6 py-4 dark:border-strokedark">
						<li>
							<div
								className="flex items-center gap-3.5 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base cursor-pointer"
								onClick={() => handeleItemClick(org)}
							>
								{org.name}
							</div>
						</li>
						<hr />
						<li>
							<div
								className="flex items-center gap-3.5 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base cursor-pointer"
								onClick={logOutOrganization}
							>
								Log out organization
							</div>
						</li>
					</ul>
				))}
			</div>
			{/* <!-- Dropdown End --> */}
		</div>
	)
}

export default DropdownOrganization
