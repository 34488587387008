import { HttpClient } from '../../httpClient'
import { IAssessment } from './interfaces.ts'

export const getAssessment = async (client: HttpClient, assessmentId: string, includes?: string[]): Promise<IAssessment> => {
	const response = await client.get<IAssessment>(`/api/v2/assessments/${assessmentId}`, {
		params: {
			includes: includes?.join(','),
		},
	})

	return response.data
}
