import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb'
import DefaultLayout from '../../layout/DefaultLayout'
import { useParams } from 'react-router-dom'
import 'survey-core/defaultV2.min.css'
import Loader from '../../common/Loader/index.tsx'
import { getEmployeeResponse, getManagerResponse, isAssessmentFilledOutByManager, isAssessmentFilledOutByUser } from '../../utils.ts'
import { generateChartData, IMergedAnswerStatistic, mergeResponses } from './utils.ts'
import Card from '../../components/Card.tsx'
import { RadarComponent } from '../../components/Charts/Radar.tsx'
import { IAssessment, IAssessmentResponse, IUser } from '../../services/nextpeakClient'
import { useRestClient } from '../../useRestClient.ts'
import { useGetAssessment, useGetUserV2 } from '../../http/hooks.ts'
import { getMe } from '../../MeProvider.ts'
import { formatQuestionText } from '../Surveys/Surveys.tsx'

const AnswersTable = ({ answers, employee }: { answers: IMergedAnswerStatistic[]; employee: IUser }) => {
	const me = getMe()
	const iAmEmployee = me.id === employee.id

	return (
		<div className="max-w-full overflow-x-auto">
			<table className="w-full table-auto">
				<thead>
					<tr className="bg-gray-2 text-left dark:bg-meta-4">
						<th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white">Question</th>
						<th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">Manager</th>
						<th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">Employee</th>
					</tr>
				</thead>
				<tbody>
					{answers.map((answer) => (
						<tr key={answer.question.id} className="border-b border-stroke dark:border-strokedark">
							<td className="py-4 px-4 text-black dark:text-white">
								{formatQuestionText(
									iAmEmployee ? answer.question.subordinateText : answer.question.managerText,
									employee.name,
									me.activeOrg?.name!
								)}
							</td>
							<td className="py-4 px-4 text-black dark:text-white">{answer.managerAnswerValue}</td>
							<td className="py-4 px-4 text-black dark:text-white">{answer.employeeAnswerValue}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	)
}

interface ChartsProps {
	employeeResponse: IAssessmentResponse
	managerResponse: IAssessmentResponse
	assessment: IAssessment
}

const Charts = ({ employeeResponse, managerResponse, assessment }: ChartsProps) => {
	const restClient = useRestClient()
	const { data, isLoading } = useGetUserV2(restClient, employeeResponse.userId)

	if (isLoading && !data) return <Loader />

	if (!data) return null

	const result = mergeResponses(employeeResponse, managerResponse)
	const chartData = generateChartData(assessment)

	return (
		<>
			<div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
				<div className="rounded-2xl bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark col-span-2">
					<h2 className="mb-0.5 text-xl font-semibold text-black dark:text-white md:text-title-lg text-center">{`${assessment.assessmentType!.position!.role} ${assessment.assessmentType!.position!.level}`}</h2>
					<p className="text-sm font-medium text-center">Assessment type</p>
				</div>
				<div className="rounded-2xl bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark">
					<h4 className="mb-0.5 text-xl font-semibold text-black dark:text-white md:text-title-lg text-center">
						{result.managerAverageScore} %
					</h4>
					<p className="text-sm font-medium text-center">Manager score</p>
				</div>
				<div className="rounded-2xl bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark">
					<h4 className="mb-0.5 text-xl font-semibold text-black dark:text-white md:text-title-lg text-center">
						{result.employeeAverageScore} %
					</h4>
					<p className="text-sm font-medium text-center">Employee score</p>
				</div>
				<div className="rounded-2xl bg-white px-5 py-6 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 col-span-2 flex flex-col justify-center">
					<div className="border-stroke p-4 dark:border-strokedark">
						{result.averageByCategory.map((category) => (
							<div key={category.category} className="items-center sm:flex mb-2">
								<div className="flex w-full max-w-42.5 items-center gap-3.5">
									<p className="font-medium text-black dark:text-white">{category.category}</p>
								</div>
								<div className="relative block h-4.5 w-full rounded bg-meta-9 dark:bg-meta-4">
									<div
										className={`absolute left-0 top-0 flex h-full items-center justify-center rounded bg-primary text-xs font-medium text-white`}
										style={{ width: `${category.managerAverageScore}%` }}
									>
										{category.managerAverageScore}%
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
				<div className="rounded-2xl bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark col-span-2">
					<RadarComponent chartData={chartData} square={false} />
				</div>
			</div>
			{result.averageByCategory.map((category) => (
				<Card key={category.category}>
					<h2 className="text-2xl mb-7 font-semibold text-black">{category.category}</h2>
					{category.subcategories.map((subcategory) => (
						<div
							key={subcategory.category}
							className="rounded-2xl border border-stroke bg-white px-5 py-6 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 my-3"
						>
							<div className="flex justify-between items-center mb-7">
								<h3 className="text-xl font-semibold text-black">{subcategory.category}</h3>
								<div className="flex items-center">
									<p className="text-sm font-medium text-center">Awarded score: </p>
									<h4 className="ml-3 text-xl font-semibold text-black dark:text-white text-center">{subcategory.managerAverageScore} %</h4>
								</div>
							</div>
							<AnswersTable answers={subcategory.answers} employee={data} />
						</div>
					))}
				</Card>
			))}
		</>
	)
}

const AssessmentDetail = () => {
	const { assessmentId } = useParams()

	if (!assessmentId) {
		return (
			<DefaultLayout>
				<Breadcrumb pageName="Assessment" />
				<div className="flex flex-col gap-10">
					<Loader />
				</div>
			</DefaultLayout>
		)
	}

	const restClient = useRestClient()
	const { data: assessment, isLoading } = useGetAssessment(restClient, assessmentId, [
		'assessmentType',
		'assessmentType.position',
		'assessmentResponses',
		'assessmentResponses.answers',
		'assessmentResponses.answers.question',
	])

	if (!assessment && isLoading) {
		return (
			<DefaultLayout>
				<Breadcrumb pageName="Assessment" />
				<div className="flex flex-col gap-10">
					<Loader />
				</div>
			</DefaultLayout>
		)
	}

	if (!assessment && !isLoading) {
		return (
			<DefaultLayout>
				<Breadcrumb pageName="Assessment" />
				<div className="flex flex-col gap-10">
					<div>Assessment not found</div>
				</div>
			</DefaultLayout>
		)
	}

	const isFilledOutByEmployee = isAssessmentFilledOutByUser(assessment!)
	const isFilledOutByManager = isAssessmentFilledOutByManager(assessment!)

	if (!isFilledOutByEmployee || !isFilledOutByManager) {
		return (
			<DefaultLayout>
				<Breadcrumb pageName="Assessment" />
				<div className="flex flex-col gap-10">
					<div>Waiting for all parties to fill out the assessment first</div>
				</div>
			</DefaultLayout>
		)
	}

	return (
		<DefaultLayout>
			<Breadcrumb pageName="Assessment" />
			<div className="flex flex-col gap-10">
				{isFilledOutByEmployee && isFilledOutByManager && (
					<Charts
						assessment={assessment!}
						employeeResponse={getEmployeeResponse(assessment!)!}
						managerResponse={getManagerResponse(assessment!)!}
					/>
				)}
			</div>
		</DefaultLayout>
	)
}

export default AssessmentDetail
