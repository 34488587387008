import { HttpClient } from '../../httpClient'
import { throwIfNotAllowedContentType, validateHttpResponse } from '../../httpClient/utils.ts'

export const completeAssessmentResponse = async (client: HttpClient, assessmentResponseId: string) => {
	const response = await client.post(`/api/v2/assessmentResponses/${assessmentResponseId}/complete`, { tmp: 'tmp' })

	await validateHttpResponse(response)
	throwIfNotAllowedContentType(response, ['application/json'])

	return response.data
}
