import PageTitle from './components/PageTitle'
import App from './App.tsx'
import { OrgAuthContext } from './OrgAuthProvider.ts'
import Card from './components/Card.tsx'

interface OrganizationSelectorProps {
	organizations: any[]
	handleOrganizationChange: (org: any, orgs: any[]) => void
}

function OrganizationSelector({ organizations, handleOrganizationChange }: OrganizationSelectorProps) {
	return (
		<div>
			<PageTitle title="Select Organization" />
			<div className="w-[100%] min-h-dvh p-7 flex justify-center items-center">
				<Card>
					<h2 className="text-center mb-2 text-2xl font-bold text-black dark:text-white">Select organization</h2>
					<div className="flex flex-col">
						{organizations.map((org: any) => (
							<button
								key={org.id}
								onClick={() => handleOrganizationChange(org.id, organizations)}
								className="w-full cursor-pointer rounded-lg border-primary border-2  p-4 mt-4 font-medium text-primary transition hover:bg-primary hover:text-white"
							>
								{org.name}
							</button>
						))}
					</div>
				</Card>
			</div>
		</div>
	)
}

interface OrganizationAuthProps {
	handleOrganizationChange: (org: any, organizations: any[]) => Promise<void>
	activeOrganizationAuth?: any
	organizationToken?: string
	userOrganizations: any[]
	logOutOrganization: () => void
}

function OrganizationAuth({
	handleOrganizationChange,
	organizationToken,
	activeOrganizationAuth,
	userOrganizations,
	logOutOrganization,
}: OrganizationAuthProps) {
	return (
		<OrgAuthContext.Provider
			value={{
				token: organizationToken,
				authOrgUser: activeOrganizationAuth,
				handleOrganizationChange,
				userOrganizations,
				logOutOrganization,
			}}
		>
			{!organizationToken && <OrganizationSelector organizations={userOrganizations} handleOrganizationChange={handleOrganizationChange} />}
			{organizationToken && <App />}
		</OrgAuthContext.Provider>
	)
}

export default OrganizationAuth
