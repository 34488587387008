import { HttpClient } from '../../httpClient'
import { throwIfNotAllowedContentType, validateHttpResponse } from '../../httpClient/utils.ts'

export const getUserOrganizationRolesV2 = async (client: HttpClient, userId: string) => {
	const response = await client.get(`/api/v2/users/${userId}/roles`)

	await validateHttpResponse(response)
	throwIfNotAllowedContentType(response, ['application/json'])

	return response.data
}
