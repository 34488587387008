import { createContext, useContext } from 'react'
import { IOrganization, IUserPosition } from './services/nextpeakClient'

interface Me {
	id: string | undefined
	email: string | undefined
	name: string | undefined
	organizations: IOrganization[]
	activeOrg: IOrganization | null
	organizationRoles: string[]
	userPosition: IUserPosition | null
}

const MeContext = createContext<Me>({
	id: undefined,
	email: undefined,
	name: undefined,
	organizations: [],
	activeOrg: null,
	organizationRoles: [],
	userPosition: null,
})

const getMe = (): Me => {
	return useContext(MeContext)
}

export { MeContext, getMe }
