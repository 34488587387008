import { HttpClient } from '../../httpClient'
import { throwIfNotAllowedContentType, validateHttpResponse } from '../../httpClient/utils.ts'
import { IUser } from './interfaces.ts'

export const getMeV2 = async (client: HttpClient): Promise<IUser> => {
	const response = await client.get<IUser>('/api/v2/users/me')

	await validateHttpResponse(response)
	throwIfNotAllowedContentType(response, ['application/json'])

	return response.data
}
