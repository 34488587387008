import { HttpClient } from '../../httpClient'
import { throwIfNotAllowedContentType, validateHttpResponse } from '../../httpClient/utils.ts'

export interface ISaveAnswer {
	questionId: string
	answerValue: number
}

export interface ISaveAnswersRequest {
	answers: ISaveAnswer[]
}

export const saveAnswers = async (client: HttpClient, assessmentResponseId: string, answers: ISaveAnswersRequest) => {
	const response = await client.post(`/api/v2/assessmentResponses/${assessmentResponseId}/answers`, answers)

	await validateHttpResponse(response)
	throwIfNotAllowedContentType(response, ['application/json'])

	return response.data
}
