import { HttpClient } from '../../httpClient'
import { throwIfNotAllowedContentType, validateHttpResponse } from '../../httpClient/utils.ts'
import { IUser } from './interfaces.ts'

export const getUserV2 = async (client: HttpClient, userId: string, includes?: string[]): Promise<IUser> => {
	const response = await client.get<IUser>(`/api/v2/users/${userId}`, {
		params: {
			includes: includes?.toString(),
		},
	})

	await validateHttpResponse(response)
	throwIfNotAllowedContentType(response, ['application/json'])

	return response.data
}
