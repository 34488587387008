import { IAnswer, IAssessment, IAssessmentResponse, IQuestion } from '../../services/nextpeakClient'
import { getEmployeeResponse, getManagerResponse } from '../../utils.ts'

export const getAssessmentResponseByUserId = (assessment: IAssessment, userId: string) => {
	const managerResponse = getManagerResponse(assessment)
	const employeeResponse = getEmployeeResponse(assessment)
	if (managerResponse?.userId === userId) {
		return {
			...managerResponse,
			type: 'manager',
		}
	}
	if (employeeResponse?.userId === userId) {
		return {
			...employeeResponse,
			type: 'employee',
		}
	}

	return null
}

export const calculateAverageScore = (answers: IAnswer[]): number => {
	const total = answers.reduce((acc, answer) => acc + answer.answerValue, 0)
	return Math.round((total / answers.length) * 10 * 100) / 100
}

interface ISubcategoryData {
	total: number
	count: number
	average: number
	answers: IAnswer[]
}

interface ICategoryData {
	total: number
	count: number
	average: number
	subcategories: {
		[subName: string]: ISubcategoryData
	}
}

interface IAveragedData {
	[name: string]: ICategoryData
}

interface ICategory {
	category: string
	average: number
}

interface ISubCategorizedData extends ICategory {
	answers: IAnswer[]
}

export interface ICategorizedData extends ICategory {
	subcategories: ISubCategorizedData[]
}

export const mapAnswersToCategoriesWithStatistics = (answers: IAnswer[]): ICategorizedData[] => {
	const averagedData = answers.reduce((acc, answer) => {
		const category = answer.question!.name
		const subcategory = answer.question!.subName
		const answerValue = answer.answerValue

		if (!acc[category]) {
			acc[category] = { total: 0, count: 0, average: 0, subcategories: {} }
		}

		if (!acc[category].subcategories[subcategory]) {
			acc[category].subcategories[subcategory] = { total: 0, count: 0, average: 0, answers: [] }
		}

		acc[category].total += answerValue
		acc[category].count += 1
		acc[category].average = acc[category].total / acc[category].count

		acc[category].subcategories[subcategory].total += answerValue
		acc[category].subcategories[subcategory].count += 1
		acc[category].subcategories[subcategory].average =
			acc[category].subcategories[subcategory].total / acc[category].subcategories[subcategory].count
		acc[category].subcategories[subcategory].answers.push(answer)

		return acc
	}, {} as IAveragedData)

	return Object.keys(averagedData).map((key) => ({
		category: key,
		average: Math.round(averagedData[key].average * 10 * 100) / 100,
		subcategories: Object.keys(averagedData[key].subcategories).map((subKey) => ({
			category: subKey,
			average: Math.round(averagedData[key].subcategories[subKey].average * 10 * 100) / 100,
			answers: averagedData[key].subcategories[subKey].answers,
		})),
	}))
}

export interface IMergedAnswerStatistic {
	employeeAnswerValue: number
	managerAnswerValue: number
	question: IQuestion
}

export interface IMergedSubCategoryStatistic {
	category: string
	employeeAverageScore: number
	managerAverageScore: number
	answers: IMergedAnswerStatistic[]
}

export interface IMergedCategoryStatistic {
	category: string
	employeeAverageScore: number
	managerAverageScore: number
	subcategories: IMergedSubCategoryStatistic[]
}

export interface IMergedResponseStatistics {
	employeeAverageScore: number
	managerAverageScore: number
	averageByCategory: IMergedCategoryStatistic[]
}

export const mergeResponses = (employeeResponse: IAssessmentResponse, managerResponse: IAssessmentResponse): IMergedResponseStatistics => {
	if (!employeeResponse.answers || !managerResponse.answers) {
		throw new Error('No employee response found.')
	}

	const result: IMergedResponseStatistics = {
		employeeAverageScore: calculateAverageScore(employeeResponse.answers),
		managerAverageScore: calculateAverageScore(managerResponse.answers),
		averageByCategory: [],
	}

	const employeeStats = mapAnswersToCategoriesWithStatistics(employeeResponse.answers)
	const managerStats = mapAnswersToCategoriesWithStatistics(managerResponse.answers)

	for (let i = 0; i < employeeStats.length; i++) {
		const employeeCategory = employeeStats[i]
		const matchingManagerCategory = managerStats.find(
			(managerCategory: ICategorizedData) => managerCategory.category === employeeCategory.category
		)

		const category: IMergedCategoryStatistic = {
			category: employeeCategory.category,
			employeeAverageScore: employeeCategory.average,
			managerAverageScore: matchingManagerCategory!.average,
			subcategories: [],
		}

		for (let j = 0; j < employeeCategory.subcategories.length; j++) {
			const employeeSubcategory = employeeCategory.subcategories[j]
			const matchingManagerSubcategory = matchingManagerCategory?.subcategories.find(
				(managerSubcategory) => managerSubcategory.category === employeeSubcategory.category
			)

			const subcategory: IMergedSubCategoryStatistic = {
				category: employeeSubcategory.category,
				employeeAverageScore: employeeSubcategory.average,
				managerAverageScore: matchingManagerSubcategory!.average,
				answers: [],
			}

			for (let k = 0; k < employeeSubcategory.answers.length; k++) {
				const employeeAnswer = employeeSubcategory.answers[k]
				const matchingManagerAnswer = matchingManagerSubcategory?.answers.find(
					(managerAnswer) => managerAnswer.question!.id === employeeAnswer.question!.id
				)

				const answer = {
					employeeAnswerValue: employeeAnswer.answerValue,
					managerAnswerValue: matchingManagerAnswer!.answerValue,
					question: employeeAnswer.question!,
				}

				subcategory.answers.push(answer)
			}

			category.subcategories.push(subcategory)
		}

		result.averageByCategory.push(category)
	}

	return result
}

export function generateChartData(assessment: IAssessment) {
	const managerResponse = getManagerResponse(assessment)!
	const employeeResponse = getEmployeeResponse(assessment)!

	const employeeStats = mapAnswersToCategoriesWithStatistics(employeeResponse.answers!)
	const managerStats = mapAnswersToCategoriesWithStatistics(managerResponse.answers!)

	return employeeStats.map((userCategory) => {
		const managerCategory = managerStats.find((managerCategory) => managerCategory.category === userCategory.category)

		return {
			category: userCategory.category,
			user: userCategory.average,
			manager: managerCategory?.average,
		}
	})
}
