import { HttpClient } from '../../httpClient'
import { throwIfNotAllowedContentType, validateHttpResponse } from '../../httpClient/utils.ts'
import { IInvite } from './interfaces.ts'

export const getOrganizationInvite = async (client: HttpClient, inviteId: string): Promise<IInvite> => {
	const response = await client.get<IInvite>(`/api/invites/${inviteId}`)

	await validateHttpResponse(response)
	throwIfNotAllowedContentType(response, ['application/json'])

	return response.data
}
