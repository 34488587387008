import { HttpClient } from '../../httpClient'
import { throwIfNotAllowedContentType, validateHttpResponse } from '../../httpClient/utils.ts'

export interface ICreateAssessmentRequest {
	employeeUserId: string
	organizationId: string
	managerUserId: string
}

export const createAssessment = async (client: HttpClient, assessment: ICreateAssessmentRequest) => {
	const response = await client.post(`/api/v2/assessments`, assessment)

	await validateHttpResponse(response)
	throwIfNotAllowedContentType(response, ['application/json'])

	return response.data
}
