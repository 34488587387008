import { useEffect, useState } from 'react'
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom'
import PageTitle from './components/PageTitle'
import Account from './pages/Account'
import { useAuth } from './AuthProvider.ts'
import Home from './pages/Home'
import Assessments from './pages/Assessments'
import Billing from './pages/Billing'
import OrganizationOverview from './pages/Organization/Overview.tsx'
import Employees from './pages/Employees'
import AssessmentDetail from './pages/Assessments/Detail.tsx'
import AssessmentSurvey from './pages/Assessments/Survey.tsx'
import { MeContext } from './MeProvider.ts'
import EmployeeDetail from './pages/Employees/Detail.tsx'
import FullPageLoader from './common/FullPageLoader'
import { useRestClient } from './useRestClient.ts'
import { useGetMeV2, useGetUserOrganizationRolesV2 } from './http/hooks.ts'
import EmployeesList from './pages/Organization/EmployeesList.tsx'
import Positions from './pages/Positions'
import InvitesList from './pages/Organization/OrganizationInvites.tsx'
import PositionDetail from './pages/Positions/Detail.tsx'

function App() {
	const [searchParams] = useSearchParams()
	const [user, setUser] = useState<any | undefined>()
	const [roleArray, setRoleArray] = useState<string[]>([])
	const { authUser } = useAuth()
	const restClient = useRestClient()
	const navigate = useNavigate() // Hook for programmatic navigation

	const { data: me, isLoading } = useGetMeV2(restClient)
	const { data: roleData, isLoading: roleLoader, error: roleError } = useGetUserOrganizationRolesV2(restClient, me?.id)

	useEffect(() => {
		const redirect = searchParams.get('redirect')
		localStorage.removeItem('postLoginRedirect')
		if (redirect) {
			navigate(redirect)
		}
	}, [])

	useEffect(() => {
		if (roleData) {
			const orgRolesArray = roleData.map((item: any) => item.name)
			setRoleArray(orgRolesArray)
		}
		if (roleError) {
			console.error(roleError)
		}
	}, [roleData, roleLoader, roleError])

	useEffect(() => {
		me && setUser(me)
	}, [me, authUser])

	// Redirect to /account if authUser.name is null
	useEffect(() => {
		if (!authUser?.name) {
			navigate('/account')
		}
	}, [authUser, navigate])

	if (!user || isLoading) {
		return <FullPageLoader />
	}

	return (
		<MeContext.Provider
			value={{
				id: user?.id,
				email: user?.email,
				fullName: user?.name,
				organizations: user?.organizations ?? [],
				organizationRoles: roleArray,
				userPosition: user?.userPosition,
				activeOrg: user?.organization,
			}}
		>
			<Routes>
				{(roleArray.includes('admin') || roleArray.includes('billing')) && (
					<Route
						path="/billing"
						element={
							<>
								<PageTitle title="Billing | NextPeak" />
								<Billing />
							</>
						}
					/>
				)}
				{roleArray.includes('member') && (
					<Route
						path="/assessments"
						element={
							<>
								<PageTitle title="Assessments | NextPeak" />
								<Assessments />
							</>
						}
					/>
				)}
				{roleArray.includes('member') && (
					<Route
						path="/assessments/:assessmentId"
						element={
							<div>
								<PageTitle title="Assessments | NextPeak" />
								<AssessmentDetail />
							</div>
						}
					/>
				)}
				{roleArray.includes('member') && (
					<Route
						path="/assessments/:assessmentId/responses/:responseId"
						element={
							<div>
								<PageTitle title="Assessments | NextPeak" />
								<AssessmentSurvey />
							</div>
						}
					/>
				)}
				<Route
					path="/account"
					element={
						<>
							<PageTitle title="Account | NextPeak" />
							<Account />
						</>
					}
				/>
				{roleArray.includes('member') && (
					<Route
						path="/employees"
						element={
							<>
								<PageTitle title="Organization | NextPeak" />
								<Employees />
							</>
						}
					/>
				)}
				{roleArray.includes('member') && (
					<Route
						path="/employees/:employeeId"
						element={
							<>
								<PageTitle title="Organization | NextPeak" />
								<EmployeeDetail />
							</>
						}
					/>
				)}
				{/*{roleArray.includes('employee') && (*/}
				{/*	<Route*/}
				{/*		path="/organization/settings"*/}
				{/*		element={*/}
				{/*			<>*/}
				{/*				<PageTitle title="Organization | Surveyjs" />*/}
				{/*				<OrganizationSettings />*/}
				{/*			</>*/}
				{/*		}*/}
				{/*	/>*/}
				{/*)}*/}
				{roleArray.includes('member') && (
					<Route
						path="/organization/graph"
						element={
							<>
								<PageTitle title="Organization | NextPeak" />
								<OrganizationOverview />
							</>
						}
					/>
				)}
				{roleArray.includes('member') && (
					<Route
						path="/organization/overview"
						element={
							<>
								<PageTitle title="Organization | NextPeak" />
								<EmployeesList />
							</>
						}
					/>
				)}
				{roleArray.includes('member') && (
					<Route
						path="/organization/invites"
						element={
							<>
								<PageTitle title="Invites | NextPeak" />
								<InvitesList />
							</>
						}
					/>
				)}
				<Route
					path="/positions"
					element={
						<>
							<PageTitle title="Positions | NextPeak" />
							<Positions />
						</>
					}
				/>
				<Route
					path="/positions/:positionId"
					element={
						<>
							<PageTitle title="Positions | NextPeak" />
							<PositionDetail />
						</>
					}
				/>
				<Route
					index
					element={
						<>
							<PageTitle title="Admin | NextPeak" />
							<Home />
						</>
					}
				/>
			</Routes>
		</MeContext.Provider>
	)
}

export default App
