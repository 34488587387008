import {
	getOrganizationUsers,
	createOrganizationInvite,
	getOrganizationRoles,
	updateOrganizationInvite,
	IInviteAction,
	getOrganizationInvite,
	ICreateOrgInviteRequest,
	getUserOrganizationRoles,
	getUser,
	updateUserOrganizationRoles,
	promoteUser,
	updateUserManager,
	getOrganizationPublic,
	getFullTrajectory,
	getOrganizationInvites,
	getPosition,
	getUserV2,
	getMeV2,
	getUserOrganizationRolesV2,
	getPositions,
	IPositionsFilters,
	IAssessmentFilters,
	getAssessments,
	getAssessment,
	createAssessment,
	ICreateAssessmentRequest,
	completeAssessmentResponse,
	ISaveAnswersRequest,
	saveAnswers,
	getUserPositionAssessments,
	IUserPositionAssessmentFilters,
} from './endpoints'
import { BearerAuthenticator, HttpClient, HttpClientFactory, NoAuthAuthenticator } from '../httpClient'

export interface INextpeakClientOpts {
	baseUrl: string
	apiToken?: string
	orgId?: string
}

export class NextpeakClient {
	readonly client: HttpClient

	constructor(
		private readonly opts: INextpeakClientOpts,
		httpClientFactory: HttpClientFactory
	) {
		this.client = httpClientFactory.createClient(this.opts.baseUrl, {
			clientName: this.constructor.name,
			defaultAuthenticator: this.opts.apiToken
				? new BearerAuthenticator(async () => {
						return {
							token: this.opts.apiToken!,
							expiresInSeconds: 3600,
						}
					})
				: new NoAuthAuthenticator(),
			axiosConfig: {
				headers: {
					'x-organization-Id': this.opts.orgId,
				},
			},
		})
	}

	async getOrganizationUsers(organizationId: string, managerId?: string, includes?: string[]) {
		return getOrganizationUsers(this.client, organizationId, managerId, includes)
	}

	async createOrganizationInvite(inviteData: ICreateOrgInviteRequest) {
		return createOrganizationInvite(this.client, inviteData)
	}

	async getOrganizationRoles() {
		return getOrganizationRoles(this.client)
	}

	async getOrganizationInvite(inviteId: string) {
		return getOrganizationInvite(this.client, inviteId)
	}

	async updateOrganizationInvite(inviteId: string, inviteAction: IInviteAction) {
		return updateOrganizationInvite(this.client, inviteId, inviteAction)
	}

	async getUserOrganizationRoles(userId: string) {
		return getUserOrganizationRoles(this.client, userId)
	}

	async getUserOrganizationRolesV2(userId: string) {
		return getUserOrganizationRolesV2(this.client, userId)
	}

	async getUser(userId: string) {
		return getUser(this.client, userId)
	}

	async updateUserOrganizationRoles(userId: string, roleIds: string[]) {
		return updateUserOrganizationRoles(this.client, userId, roleIds)
	}

	async promoteUser(userId: string) {
		return promoteUser(this.client, userId)
	}

	async updateUserManager(userId: string, managerId: string) {
		return updateUserManager(this.client, userId, managerId)
	}

	async getOrganizationPublic(organizationId: string) {
		return getOrganizationPublic(this.client, organizationId)
	}

	async getFullTrajectory(path: string) {
		return getFullTrajectory(this.client, path)
	}

	async getOrganizationInvites(organizationId: string) {
		return getOrganizationInvites(this.client, organizationId)
	}

	async getPosition(positionId: string, includes?: string[]) {
		return getPosition(this.client, positionId, includes)
	}

	async getPositions(filter?: IPositionsFilters) {
		return getPositions(this.client, filter)
	}

	async getUserV2(userId: string, includes?: string[]) {
		return getUserV2(this.client, userId, includes)
	}

	async getMeV2() {
		return getMeV2(this.client)
	}

	async getAssessments(filters?: IAssessmentFilters) {
		return getAssessments(this.client, filters)
	}

	async getAssessment(assessmentId: string, includes?: string[]) {
		return getAssessment(this.client, assessmentId, includes)
	}

	async createAssessment(assessment: ICreateAssessmentRequest) {
		return createAssessment(this.client, assessment)
	}

	async completeAssessmentResponse(assessmentId: string) {
		return completeAssessmentResponse(this.client, assessmentId)
	}

	async saveAnswers(assessmentResponseId: string, answers: ISaveAnswersRequest) {
		return saveAnswers(this.client, assessmentResponseId, answers)
	}

	async getUserPositionAssessments(userId: string, filters: IUserPositionAssessmentFilters) {
		return getUserPositionAssessments(this.client, userId, filters)
	}
}
