import { HttpClient } from '../../httpClient'
import { throwIfNotAllowedContentType, validateHttpResponse } from '../../httpClient/utils.ts'
import { IUser } from './interfaces.ts'

export const getOrganizationUsers = async (
	client: HttpClient,
	organizationId: string,
	managerId?: string,
	includes?: string[]
): Promise<IUser[]> => {
	const response = await client.get<IUser[]>(`/api/organizations/${organizationId}/users`, {
		params: {
			managerId,
			includes: includes ? includes.join(',') : undefined,
		},
	})

	await validateHttpResponse(response)
	throwIfNotAllowedContentType(response, ['application/json'])

	return response.data
}
