import { HttpClient } from '../../httpClient'
import { throwIfNotAllowedContentType, validateHttpResponse } from '../../httpClient/utils.ts'
import { IPosition } from './interfaces.ts'

export interface IPositionsFilters {
	role?: string
	level?: string
	includes?: string[]
}

export const getPositions = async (client: HttpClient, filters?: IPositionsFilters): Promise<IPosition[]> => {
	const response = await client.get<IPosition[]>(`/api/v2/positions`, {
		params: {
			...filters,
			includes: filters?.includes?.join(','),
		},
	})

	await validateHttpResponse(response)
	throwIfNotAllowedContentType(response, ['application/json'])

	return response.data
}
