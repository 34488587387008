import { IAssessment, IPosition } from './services/nextpeakClient'

export const isAssessmentFilledOutByUser = (assessment: IAssessment) => {
	const userResponse = assessment.assessmentResponses?.find((response) => response.userId === assessment.employeeUserId)
	return !!userResponse?.completedAt
}

export const getEmployeeResponse = (assessment: IAssessment) => {
	return assessment.assessmentResponses?.find((response) => response.userId === assessment.employeeUserId)
}

export const isAssessmentFilledOutByManager = (assessment: IAssessment) => {
	const managerResponse = assessment.assessmentResponses?.find((response) => response.userId === assessment.managerUserId)
	return !!managerResponse?.completedAt
}

export const getManagerResponse = (assessment: IAssessment) => {
	return assessment.assessmentResponses?.find((response) => response.userId === assessment.managerUserId)
}

export const transformPositionsArray = (positions: IPosition[]) => {
	// Step 1: Create a map to accumulate levels for each title
	const titleMap = new Map()

	// Step 2: Populate the map with levels for each title
	positions.forEach(({ level, role }) => {
		if (!titleMap.has(role)) {
			titleMap.set(role, [])
		}
		titleMap.get(role).push(level)
	})

	// Step 3: Convert the map into the desired output format
	return Array.from(titleMap, ([role, levels]) => ({
		role,
		levels,
	}))
}

export const getPositionByRoleAndLevel = (positions: IPosition[], role?: string, level?: string) => {
	if (!role || !level) return undefined
	return positions.find((position) => position.role === role && position.level === level)
}
