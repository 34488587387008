import { HttpClient } from '../../httpClient'
import { throwIfNotAllowedContentType, validateHttpResponse } from '../../httpClient/utils.ts'
import { IPosition } from './interfaces.ts'

export const getPosition = async (client: HttpClient, positionId: string, includes?: string[]): Promise<IPosition> => {
	const response = await client.get<IPosition>(`/api/v2/positions/${positionId}`, {
		params: {
			includes: includes?.toString(),
		},
	})

	await validateHttpResponse(response)
	throwIfNotAllowedContentType(response, ['application/json'])

	return response.data
}
