import dayjs from 'dayjs'

export const snakeToWords = (str: string) => {
	// Split the string by underscore
	let words = str.split('_')

	// Capitalize the first word and keep the rest in lowercase
	words = words.map((word, index) => {
		return index === 0 ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : word.toLowerCase()
	})

	// Join the words back with spaces
	return words.join(' ')
}

export const toSnakeCase = (str: string): string => {
	return str.toLowerCase().replace(/\s+/g, '_')
}

export const toCamelCase = (str: string): string => {
	return str
		.toLowerCase()
		.split(' ')
		.map((word, index) => {
			if (index === 0) {
				return word
			}
			return word.charAt(0).toUpperCase() + word.slice(1)
		})
		.join('')
}

export const toCapitalized = (str: string): string => {
	return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export const fromCamelCase = (str: string): string => {
	return str
		.replace(/([A-Z])/g, ' $1')
		.toLowerCase()
		.trim()
}

export const formatTimestamp = (timestamp: string, format: string) => {
	const date = dayjs(timestamp)
	return date.format(format)
}
