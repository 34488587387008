import { HttpClient } from '../../httpClient'
import { throwIfNotAllowedContentType, validateHttpResponse } from '../../httpClient/utils.ts'
import { IPublicOrganization } from './interfaces.ts'

export const getOrganizationPublic = async (client: HttpClient, organizationId: string): Promise<IPublicOrganization> => {
	const response = await client.get<IPublicOrganization>(`/api/public/organizations/${organizationId}`)

	await validateHttpResponse(response)
	throwIfNotAllowedContentType(response, ['application/json'])

	return response.data
}
