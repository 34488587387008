import { HttpClient } from '../../httpClient'
import { throwIfNotAllowedContentType, validateHttpResponse } from '../../httpClient/utils.ts'
import { IInvite } from './interfaces.ts'

export const getOrganizationInvites = async (client: HttpClient, organizationId: string): Promise<IInvite[]> => {
	const response = await client.get<IInvite[]>(`/api/organizations/${organizationId}/invites`)

	await validateHttpResponse(response)
	throwIfNotAllowedContentType(response, ['application/json'])

	return response.data
}
