import { HttpClient } from '../../httpClient'
import { throwIfNotAllowedContentType, validateHttpResponse } from '../../httpClient/utils.ts'
import { IPositionTrajectory } from './interfaces.ts'

export const getFullTrajectory = async (client: HttpClient, path: string): Promise<IPositionTrajectory[]> => {
	const response = await client.get<IPositionTrajectory[]>(`/api/trajectory/${path}`)

	await validateHttpResponse(response)
	throwIfNotAllowedContentType(response, ['application/json'])

	return response.data
}
