import { HttpClient } from '../../httpClient'
import { IAssessment } from './interfaces.ts'

export interface IUserPositionAssessmentFilters {
	latest: 1 | 0
	isCompleted?: 1 | 0
	includes?: string[]
}

export const getUserPositionAssessments = async (
	client: HttpClient,
	userId: string,
	filters?: IUserPositionAssessmentFilters
): Promise<IAssessment[]> => {
	const response = await client.get<IAssessment[]>(`/api/v2/users/${userId}/positionAssessments`, {
		params: {
			...filters,
			includes: filters?.includes?.join(','),
		},
	})

	return response.data
}
