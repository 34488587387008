import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
	IAssessment,
	IAssessmentFilters,
	ICreateAssessmentRequest,
	ICreateOrgInviteRequest,
	IInvite,
	IInviteAction,
	IPosition,
	IPositionsFilters,
	IPositionTrajectory,
	IPublicOrganization,
	ISaveAnswersRequest,
	IUser,
	IUserPositionAssessmentFilters,
	NextpeakClient,
} from '../services/nextpeakClient'
import { toast } from 'react-toastify'

export const useGetOrganizationUsers = <TData = IUser[], TError = unknown>(
	client: NextpeakClient,
	organizationId: string,
	managerId?: string,
	includes?: string[],
	options?: Omit<UseQueryOptions<IUser[], TError, TData>, 'queryKey'>
) => {
	const opts = options || {}
	return useQuery<IUser[], TError, TData>({
		queryKey: ['GetOrganizationUsers', organizationId, managerId, includes],
		queryFn: () => client.getOrganizationUsers(organizationId, managerId, includes),
		...opts,
	})
}

export const useCreateOrgInvite = (client: NextpeakClient) => {
	return useMutation({
		mutationFn: (inviteData: ICreateOrgInviteRequest) => client.createOrganizationInvite(inviteData),
		onSuccess: (res) => {
			console.log(res)
			toast.success('Invite created')
		},
		onError: (error) => {
			// Optionally handle errors here, like showing a notification
			console.error('Create of org Invite failed:', error)
			toast.error('Create of org Invite failed')
		},
	})
}

export const useGetOrganizationRoles = (client: NextpeakClient) => {
	return useQuery<unknown, Error, any[]>({ queryKey: ['GetOrganizationRoles'], queryFn: () => client.getOrganizationRoles() })
}

export const useGetOrganizationInvite = <TData = IInvite, TError = unknown>(
	client: NextpeakClient,
	inviteId: string,
	options?: Omit<UseQueryOptions<IInvite, TError, TData>, 'queryKey'>
) => {
	const opts = options || {}
	return useQuery<IInvite, TError, TData>({
		queryKey: ['GetOrganizationInvite', inviteId],
		queryFn: () => client.getOrganizationInvite(inviteId),
		...opts,
	})
}

export const useGetUserOrganizationRoles = (client: NextpeakClient, userId?: string) => {
	return useQuery<unknown, Error, any[]>({
		queryKey: ['GetUserOrganizationRoles', userId],
		queryFn: () => client.getUserOrganizationRoles(userId!),
		enabled: !!userId,
	})
}

export const useGetUserOrganizationRolesV2 = (client: NextpeakClient, userId?: string) => {
	return useQuery<unknown, Error, any[]>({
		queryKey: ['GetUserOrganizationRoles', userId],
		queryFn: () => client.getUserOrganizationRolesV2(userId!),
		enabled: !!userId,
	})
}

export const useUpdateOrgInvite = (client: NextpeakClient) => {
	return useMutation({
		mutationFn: ({ inviteId, inviteData }: { inviteId: string; inviteData: IInviteAction }) =>
			client.updateOrganizationInvite(inviteId, inviteData),
		onSuccess: (res) => {
			console.log(res)
			toast.success('Invite successfully updated')
		},
		onError: (error) => {
			// Optionally handle errors here, like showing a notification
			console.error(error)
			toast.error('Update of org Invite failed')
		},
	})
}

export const useUpdateUserOrganizationRoles = (client: NextpeakClient) => {
	return useMutation({
		mutationFn: ({ userId, roleIds }: { userId: string; roleIds: string[] }) => client.updateUserOrganizationRoles(userId, roleIds),
		onSuccess: (res) => {
			toast.success('User roles updated')
		},
		onError: (error) => {
			console.error(error)
			toast.error('Roles update failed')
		},
	})
}

export const useGetUser = (client: NextpeakClient, userId: string) => {
	return useQuery<unknown, Error, any>({ queryKey: ['GetUser', userId], queryFn: () => client.getUser(userId) })
}

export const useGetUserV2 = <TData = IUser, TError = unknown>(
	client: NextpeakClient,
	userId: string,
	includes?: string[],
	options?: Omit<UseQueryOptions<IUser, TError, TData>, 'queryKey'>
) => {
	const opts = options || {}
	return useQuery<IUser, TError, TData>({
		queryKey: ['GetUser', userId, includes],
		queryFn: () => client.getUserV2(userId, includes),
		...opts,
	})
}

export const useGetMeV2 = <TData = IUser, TError = unknown>(
	client: NextpeakClient,
	options?: Omit<UseQueryOptions<IUser, TError, TData>, 'queryKey'>
) => {
	const opts = options || {}
	return useQuery<IUser, TError, TData>({ queryKey: ['GetMe'], queryFn: () => client.getMeV2(), ...opts })
}

export const usePromoteUser = (client: NextpeakClient) => {
	return useMutation({
		mutationFn: ({ userId }: { userId: string }) => client.promoteUser(userId),
		onSuccess: (res) => {
			toast.success('User promoted')
		},
		onError: (error) => {
			console.error(error)
			toast.error('Promotion failed')
		},
	})
}

export const useUpdateUserManager = (client: NextpeakClient) => {
	return useMutation({
		mutationFn: ({ userId, newManagerId }: { userId: string; newManagerId: string }) => client.updateUserManager(userId, newManagerId),
		onSuccess: (res) => {
			toast.success('User promoted')
		},
		onError: (error) => {
			console.error(error)
			toast.error('Promotion failed')
		},
	})
}

export const useGetOrganizationPublic = <TData = IPublicOrganization, TError = unknown>(
	client: NextpeakClient,
	organizationId?: string,
	options?: Omit<UseQueryOptions<IPublicOrganization, TError, TData>, 'queryKey'>
) => {
	const opts = options || {}
	return useQuery<IPublicOrganization, TError, TData>({
		queryKey: ['GetOrganizationPublic', organizationId],
		queryFn: () => client.getOrganizationPublic(organizationId!),
		...opts,
	})
}

export const useGetFullTrajectory = <TData = IPositionTrajectory[], TError = unknown>(
	client: NextpeakClient,
	path: string,
	options?: Omit<UseQueryOptions<IPositionTrajectory[], TError, TData>, 'queryKey'>
) => {
	const opts = options || {}
	return useQuery<IPositionTrajectory[], TError, TData>({
		queryKey: ['GetFullTrajectory', path],
		queryFn: () => client.getFullTrajectory(path),
		...opts,
	})
}

export const useGetOrganizationInvites = <TData = IInvite[], TError = unknown>(
	client: NextpeakClient,
	organizationId: string,
	options?: Omit<UseQueryOptions<IInvite[], TError, TData>, 'queryKey'>
) => {
	const opts = options || {}
	return useQuery<IInvite[], TError, TData>({
		queryKey: ['GetOrganizationInvites', organizationId],
		queryFn: () => client.getOrganizationInvites(organizationId),
		...opts,
	})
}

export const useGetPosition = <TData = IPosition, TError = unknown>(
	client: NextpeakClient,
	positionId: string,
	includes?: string[],
	options?: Omit<UseQueryOptions<IPosition, TError, TData>, 'queryKey'>
) => {
	const opts = options || {}
	return useQuery<IPosition, TError, TData>({
		queryKey: ['GetPosition', positionId, includes],
		queryFn: () => client.getPosition(positionId, includes),
		...opts,
	})
}

export const useGetPositions = <TData = IPosition[], TError = unknown>(
	client: NextpeakClient,
	filters?: IPositionsFilters,
	options?: Omit<UseQueryOptions<IPosition[], TError, TData>, 'queryKey'>
) => {
	const opts = options || {}
	return useQuery<IPosition[], TError, TData>({
		queryKey: ['GetPositions', filters],
		queryFn: () => client.getPositions(filters),
		...opts,
	})
}

export const useGetAssessments = <TData = IAssessment[], TError = unknown>(
	client: NextpeakClient,
	filters?: IAssessmentFilters,
	options?: Omit<UseQueryOptions<IAssessment[], TError, TData>, 'queryKey'>
) => {
	const opts = options || {}
	return useQuery<IAssessment[], TError, TData>({
		queryKey: ['GetAssessments', filters],
		queryFn: () => client.getAssessments(filters),
		...opts,
	})
}

export const useGetAssessment = <TData = IAssessment, TError = unknown>(
	client: NextpeakClient,
	assessmentId: string,
	includes?: string[],
	options?: Omit<UseQueryOptions<IAssessment, TError, TData>, 'queryKey'>
) => {
	const opts = options || {}
	return useQuery<IAssessment, TError, TData>({
		queryKey: ['GetAssessments', assessmentId, includes],
		queryFn: () => client.getAssessment(assessmentId, includes),
		...opts,
	})
}

export const useCreateAssessment = (client: NextpeakClient) => {
	return useMutation({
		mutationFn: (input: ICreateAssessmentRequest) => client.createAssessment(input),
		onSuccess: (res) => {
			toast.success('Assessment created')
		},
		onError: (error) => {
			console.error(error)
			toast.error('Assessment creation failed')
		},
	})
}

export const useCompleteAssessmentResponse = (client: NextpeakClient) => {
	return useMutation({
		mutationFn: (assessmentId: string) => client.completeAssessmentResponse(assessmentId),
		onSuccess: (res) => {
			toast.success('Assessment response completed')
		},
		onError: (error) => {
			console.error(error)
			toast.error('Assessment response completion failed')
		},
	})
}

export const useSaveAnswers = (client: NextpeakClient) => {
	return useMutation({
		mutationFn: ({ assessmentResponseId, answers }: { assessmentResponseId: string; answers: ISaveAnswersRequest }) =>
			client.saveAnswers(assessmentResponseId, answers),
		onSuccess: (res) => {
			toast.success('Assessment response completed')
		},
		onError: (error) => {
			console.error(error)
			toast.error('Assessment response completion failed')
		},
	})
}

export const useGetUserPositionAssessments = <TData = IAssessment[], TError = unknown>(
	client: NextpeakClient,
	userId: string,
	filters: IUserPositionAssessmentFilters,
	options?: Omit<UseQueryOptions<IAssessment[], TError, TData>, 'queryKey'>
) => {
	const opts = options || {}
	return useQuery<IAssessment[], TError, TData>({
		queryKey: ['GetUserPositionAssessments', userId, filters],
		queryFn: () => client.getUserPositionAssessments(userId, filters),
		...opts,
	})
}
