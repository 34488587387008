import { HttpClient } from '../../httpClient'
import { IAssessment } from './interfaces.ts'

export interface IAssessmentFilters {
	assessmentType?: string
	employeeUserId?: string
	managerUserId?: string
	includes?: string[]
}

export const getAssessments = async (client: HttpClient, filters?: IAssessmentFilters): Promise<IAssessment[]> => {
	const response = await client.get<IAssessment[]>('/api/v2/assessments', {
		params: {
			...filters,
			includes: filters?.includes?.join(','),
		},
	})

	return response.data
}
